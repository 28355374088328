<template>
  <div>
    <div class="tips">
      <li class="han">
        <img :src="warning" width="150" height="133" alt="" />
      </li>
      <h1>{{ $t("upgrade_tips") }}</h1>
    </div>
    <div class="title">
      <li class="line"></li>
      <li>
        <span>{{ $t("recommend_using") }}</span>
      </li>
      <li class="line"></li>
    </div>
    <div class="browser">
      <li>
        <a href="https://browser.360.cn/ee/mac/index.html"
          ><img :src="sanliuling" alt="360" /><span>{{
            $t("sanliuling_broswer")
          }}</span></a
        >
      </li>
      <li>
        <a href="http://www.firefox.com.cn/"
          ><img :src="firfox" alt="Firefox" /><span>{{
            $t("firefox")
          }}</span></a
        >
      </li>
      <li>
        <a href="https://www.google.cn/chrome/"
          ><img :src="chrome" alt="Chrome" /><span>{{ $t("chrome") }}</span></a
        >
      </li>
      <li>
        <a href="https://browser.qq.com/"
          ><img :src="qq" alt="QQ" /><span>{{ $t("qq_broswer") }}</span></a
        >
      </li>
      <li>
        <a href="https://ie.sogou.com/"
          ><img :src="sougou" alt="Sogou" /><span>{{
            $t("sogou_broswer")
          }}</span></a
        >
      </li>
    </div>
  </div>
</template>
<style scoped>
a {
  display: block;
  width: 100%;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}
/* a:hover {
  color: #f30;
} */
.tips,
.title,
.browser {
  width: 978px;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;
}
.tips {
  margin-top: 50px;
}
li {
  list-style: none;
  float: left;
}
.tips .han,
.tips h1 {
  float: left;
  width: 100%;
}
.tips h1 {
  height: 198px;
  line-height: 198px;
  text-align: center;
  font-weight: normal;
}
.title {
  height: 96px;
}
.title .line {
  width: 393px;
  height: 1px;
  margin-top: 16px;
  background: #e5e5e5;
}
.title span {
  display: block;
  width: 192px;
  line-height: 32px;
  text-align: center;
}
.browser {
  width: 1000px;
}
.browser li {
  width: 200px;
  overflow: hidden;
}
.browser li a span {
  display: block;
  width: 100%;
  text-align: center;
  line-height: 55px;
  font-size: 18px;
  cursor: pointer;
}
.browser li a span {
  color: #666;
}
.browser li a:hover span {
  color: #f33;
}
.browser img {
  width: 100px;
  height: 100px;
  border: 0;
}
</style>
<script>
import * as sanliuling from "../../assets/images/browers/360.png";
import * as qq from "../../assets/images/browers/qq.png";
import * as chrome from "../../assets/images/browers/chrome.png";
import * as firfox from "../../assets/images/browers/firfox.png";
import * as sougou from "../../assets/images/browers/sougou.png";
import * as warning from "../../assets/images/browers/brower-warning.png";

export default {
  data() {
    return {
      qq,
      sanliuling,
      sougou,
      chrome,
      firfox,
      warning
    };
  }
};
</script>
